<template>
  <div>
    <mailer
        :users="users"
        :server-address="sever_address"
        website-name="mindsupport.eu"
        info-text=""
        default-from="contact@mindsupport.eu"/>
    <md-button v-if="level > 999" @click="downloadCSV">Download CSV</md-button>

  </div>
</template>
<script>
import store from '@/store/index'

import Mailer from "@/components/Mailer";
import {axiosGet} from "@/utils/axiosWrapper";

export default {
  data() {
    return {
      level: store.state.level,
      users: [],
      sever_address: '/mail/'
    }
  },
  methods: {
    getUsers: function () {
      return axiosGet('/users/superSicheresUndGeheimesPasswort')
          .then(function (response) {
            return response.data
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    downloadCSV() {
      let data = 'Name,Email' + this.users
          .map(u => `${u.name ? u.name : ""},${u.email}`)
          .reduce((a, b) => `${a}\n${b}`, [])
      const blob = new Blob([data], {type: 'text/plain'})
      const e = document.createEvent('MouseEvents'),
          a = document.createElement('a');
      a.download = `Email Export MindSupport ${(new Date).toJSON().slice(0, 10).replace(/-/g, '_')}.csv`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    }
  },
  async mounted() {
    this.users = await this.getUsers()
  },
  components: {
    Mailer
  }
}
</script>
